<template>
  <div class="visit-temporary-exist">
    <van-tabs v-model="activeName" @click="onClick" sticky>
      <van-tab title="已有客户" name="a" style="overflow-y: auto;">
        <!-- 搜索框-->
        <van-row style="background-color: white">
          <van-search v-model="selectCustomerValue" placeholder="请输入客户名称" @cancel="cancel('1')" show-action/>
        </van-row>
        <van-row>
          <van-col span="11" :style="{fontSize:'15px'}">当前查询数据条数：{{ this.list.length }}条</van-col>
        </van-row>
        <!-- 列表-->
        <div ref="fresh">
          <div v-if="list.length<=0">
            <van-empty image="error" description="没有数据"/>
          </div>
          <div v-else>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh('001', selectCustomerValue)">
              <van-list
                  v-model="loading"
                  loading-text="加载中"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="nextPage('001', selectCustomerValue)"
                  style="margin-bottom: 45px"
              >
                <visit-temporary-cell v-for="item in list" v-bind:key="item.id" :data="item"/>
              </van-list>
            </van-pull-refresh>
          </div>
        </div>
        <!-- 添加按钮-->
        <Add to="/BulkCrmsVisitTemporaryExistAdd/" :param=Math.random().toString(36).slice(2)></Add>
      </van-tab>
      <van-tab title="新增客户" name="b" style="overflow-y: auto;">
        <!-- 搜索框-->
        <van-row style="background-color: white">
          <van-search v-model="selectCustomerValue1" placeholder="请输入客户名称" @cancel="cancel('2')" show-action/>
        </van-row>
        <van-row>
          <van-col span="11" :style="{fontSize:'15px'}">当前查询数据条数：{{ this.list.length }}条</van-col>
        </van-row>
        <!-- 列表-->
        <div ref="fresh">
          <div v-if="list.length<=0">
            <van-empty image="error" description="没有数据"/>
          </div>
          <div v-else>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh('002', selectCustomerValue1)">
              <van-list
                  v-model="loading"
                  loading-text="加载中"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="nextPage('002', selectCustomerValue1)"
                  style="margin-bottom: 45px"
              >
                <visit-temporary-cell v-for="item in list" v-bind:key="item.id" :data="item"/>
              </van-list>
            </van-pull-refresh>
          </div>
        </div>
        <!-- 添加按钮-->
        <Add to="/BulkCrmsVisitTemporaryNewAdd/" :param=Math.random().toString(36).slice(2)></Add>
      </van-tab>
    </van-tabs>

    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="2"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import VisitTemporaryCell from '@/components/VisitTemporaryCell/index'
import Add from '@/components/Add';
import {Toast} from 'vant';

export default {
  name: "BulkCrmsVisitTemporaryExistList",
  components: {VisitTemporaryCell, CrmTabBar, Add, Toast},
  data() {
    return {
      selectCustomerValue: '',
      selectCustomerValue1: '',
      activeName: '',
      isAll: false,
      isShowDialog: false,
      colors: '',
      value: '',
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      list: [],
      currentPage: 1, // 当前页码
      pageSize: 25, // 每页个数
      loading: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 20
    this.onRefresh('001', this.selectCustomerValue);
  },
  watch: {
    //监听公司名头
    'selectCustomerValue': function () {
      this.onRefresh('001', this.selectCustomerValue)
    },
    'selectCustomerValue1': function () {
      this.onRefresh('002', this.selectCustomerValue1)
    },
  },
  methods: {
    // 下拉刷新
    onRefresh(index, name) {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData(index, name)
    },
    // 加载下一页
    nextPage(index, name) {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData(index, name)
        }
      }, 500)
    },
    // 下拉刷新
    getData(index, name) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.loading = true
      this.$ajax.post('/api/ajax/visitTemporary/getBulkCrmReplayList.json',
          {
            customerName: name,
            tempraryVisit: index,
            current: this.currentPage,
            size: this.pageSize,
          }).then(res => {
        this.loading = false
        if (res.status == 200) {
          // 如果下拉刷新，那么数据清空重新获取第一页
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          console.log(res.data)
          res.data.forEach(item => {
            this.list.push(item)
          })
          this.listRow = this.list.length
          // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
          if (res.data.length < this.pageSize - 1) {
            this.finished = true
          } else {
            this.finished = false
          }
          for (let i = 0; i < this.list.length; i++) {
            this.list[i].to = '/BulkCrmsVisitTemporaryExistEdit/' + this.list[i].replayId
            this.list[i].detail = '查看详情'
          }
          Toast.success('加载成功');
        }
      }).catch(err => {
        Toast.fail('加载失败');
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    reverseAll() {
      this.isAll = !this.isAll
      this.isShowDialog = true;
    },
    getMsg(data) {
      this.isAll = !this.isAll
      this.isShowDialog = false;
      console.log(data)
    },
    onClick(name, title) {
      console.log(name + "****" + title)
      if (name == 'b') {
        this.onRefresh('002', this.selectCustomerValue1)
      }
      if (name == 'a') {
        this.onRefresh('001', this.selectCustomerValue)
      }
    },
    cancel(index) {
      if (index == '1') {
        this.selectCustomerValue = ''
      } else {
        this.selectCustomerValue1 = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
<style>
.visit-temporary-exist {
  background-color: #FFFFFF;
}

.visit-temporary-exist .van-tab {
  color: #FFF7FD;
  font-weight: 500;
  background-color: #0084F8;
}
</style>